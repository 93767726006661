:root{
  background-color: black !important;
  overflow-x: hidden !important;
}

body{
  overflow-x: hidden !important;
}


.banner{
  box-shadow: 0px 0px 30vw 0px rgba(0,0,0,0.75) inset;
  -webkit-box-shadow: 0px 0px 30vw 0px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 30vw 0px rgba(0,0,0,0.75) inset;
}

.mail{
  opacity: 0.4 !important;
  cursor:  pointer !important;
  transition: all 1s ease-out !important;
}

.mail:hover{
  opacity: 1.0 !important;
  
}

.arrow{
  opacity: 0.5 !important;
  font-size: 5rem !important;
  cursor:  pointer !important;
}

.arrow:hover{
  opacity: 1.0 !important;
  transition: all 1s ease-out !important;
}

.vertical-timeline::before{
  opacity: 0.2 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; 
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; 
}

.vertical-timeline-element-content{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.vertical-timeline-element-content-arrow{
  opacity: 0.1 !important;
}

.vertical-timeline-element-icon{
 
  display: none !important;
}

 .vertical-timeline-element-content{
  background-color: rgba(255, 255, 255, 0.1) !important;
 
}

.vertical-timeline-element-icon svg{

  opacity: 0.8 !important;


}



.hr{
  height: 4px !important;
  width: 100% !important;
  background-color: white !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; 
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; 
  opacity: 0.2;
}

.modal-content, .modal-dialog, .modal-body{

  background: none !important;
}
